<template>
	<div v-if="show">
		<div class="item_line">
			<span class="titlename" style="font-size: 18px; color: #333; font-weight: bold">{{ form.name }}</span>
		</div>
		<div class="item_line">
			<span class="titlename">会议日程</span>
		</div>
		<div class="item_line" style="margin-left: 10px; width: 70%; justify-content: flex-end"
			v-show="!istz && roleid == 2">
			<el-button type="primary" size="mini" @click="showselect = !showselect">{{
        showselect ? "取消编辑" : "编辑"
      }}</el-button>
		</div>
		
		
		<div style="width: 70%; margin-left: 10px">
			<div v-show="!showselect" v-html="textarea" style="width: 100%; border: none; height: auto; font-size: 14px">
				
			</div>
			<div v-show="showselect" style="border: 1px solid #ccc;width: 100%;">
				<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
					mode="default" />
				<Editor style="height: 300px; overflow-y: hidden;font-size: 16px;" v-model="textarea"
					:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
			</div>
			
		</div>
		
		
		<div class="item_line" style="
        margin-left: 10px;
        width: 70%;
        justify-content: center;
        margin-top: 20px;
      ">
			<el-button type="primary" v-show="showselect" @click="save">确认保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default {
		components: {
			Editor,
			Toolbar
		},
		props: {
			istz: {
				type: Boolean,
				default: false,
			},
			updataQx: {
				type: Number,
				default: 1,
			},
		},
		data() {
			return {
				show: false,
				form: "",
				showselect: false,
				textarea: "",
				roleid: 0,
				
				//富文本相关
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {
				
								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
				
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
				
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
				
							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒
				
							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
				
							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},
				
							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},
				
							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},
				
							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},
				
						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},
			};
		},
		created() {
			this.roleid = this.updataQx;
		},
		methods: {
			save() {
				this.$post({
					url: "/api/wymeeting/edit2",
					params: {
						hyrc: this.textarea,
						id: this.form.id,
					},
				}).then((res) => {
					this.showselect = false;
					this.$message.success("操作成功");
					this.$emit("getinfo");
				});
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			}
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	};
</script>

<style lang="scss" scoped>
	.tapmenu {
		font-size: 16px;
		margin-right: 16px;
		cursor: pointer;
		padding-bottom: 5px;
	}

	.active_tap {
		color: #ec4a4a;
		border-bottom: 2px solid #ec4a4a;
	}

	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.titlename {
			margin-left: 10px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 85vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: "" !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .myinputicon {
		cursor: pointer;
	}

	::v-deep .myinputicon:hover {
		color: red !important;
	}
</style>